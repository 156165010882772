export const Timeout = (ms = 0) => {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function () {
      setTimeout(() => {
        originalMethod.apply(this, arguments);
      }, ms);
    };

    return descriptor;
  };
};
