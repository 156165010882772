<aside class="2xl:px-10 lg:px-6 bg-rounded border-l-0 min-h-screen">
  <h2 class="mt-4 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
    {{ 'chat.history.title.recent' | transloco }}
  </h2>

  <ng-container>
    <!-- SearchBox -->
    <form class="max-w-md mx-auto mb-3" *ngIf="groupedChats()?.length || query !== undefined">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <app-search-icon></app-search-icon>
        </div>
        <input
          type="search"
          id="default-search"
          class="border border-gray-300 text-gray-900 rounded-lg focus:ring-coral-300 focus:border-coral-300 block w-full p-2 ps-10 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-coral-700 dark:focus:border-coral-700"
          placeholder="Search chat history ..."
          [formControl]="search"
        />
      </div>
    </form>

    <div
      *ngIf="groupedChats()?.length; else emptyState"
      id="chat-history"
      class="overflow-x-hidden"
      appInfiniteScroll
      (loadMore)="loadNextPage()"
    >
      <!-- Recent Histories -->
      <ng-container *ngFor="let chatGroup of groupedChats(); let $groupIndex = index">
        <div class="mt-4 mb-2 py-1 text-xs font-semibold text-gray-500" *ngIf="chatGroup.category !== 'initial'">
          {{ chatGroup.category | transloco }} {{ chatGroup.year }}
        </div>
        <ul class="max-w-md space-y-1 text-gray-700 list-none list-inside dark:text-gray-400">
          <ng-container *ngFor="let chat of chatGroup.chats; let $index = index">
            <li
              class="relative group ease-in-out duration-150 transition-all"
              [ngClass]="activeChatId() === chat.chatId ? 'text-coral-600 font-bold' : 'hover:font-bold'"
            >
              <a [routerLink]="'/chat/' + chat.chatId" class="truncate block">
                <span class="text-lg mdi mdi-message-outline mr-3 align-middle"></span> {{ chat.title }}
              </a>
              <span
                [tp]="chatActionsMenu"
                tpPlacement="right"
                tpVariation="menu"
                [tpData]="chat"
                class="mdi mdi-dots-vertical absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer bg-white px-2 hover:text-gray-700 hover:bg-dark-purple-200 hover:rounded-full transition-all ease-in-out duration-300"
              ></span>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <!-- Show more & less buttons -->
      <!-- <button
      id="btn-show-more"
      data-accordion="open"
      data-accordion-target="#chat-more-history"
      aria-expanded="false"
      aria-controls="chat-more-history"
      class="text-sm px-4 py-1 mt-3 rounded-full text-dark-purple-500 bg-transparent border hover:bg-light-purple-200 transition-all ease-in-out duration-300"
    >
      <span class="mdi mdi-chevron-down"></span> Show more
    </button> -->
    </div>
  </ng-container>
</aside>

<ng-template #emptyState>
  <div class="px-2 py-8">
    <p class="text-gray-400 text-center text-sm">
      There are no conversations yet. Start a new chat to see it appear here!
    </p>
  </div>
</ng-template>

<ng-template #chatActionsMenu let-hide let-chat="data">
  <ul class="text-sm text-gray-700 dark:text-gray-200">
    <li
      class="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
      (click)="openDeleteChatDialog(chat.chatId); hide()"
    >
      {{ 'common.delete' | transloco }}
    </li>
  </ul>
</ng-template>
