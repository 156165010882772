import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, map, Observable, of, take, tap, withLatestFrom } from 'rxjs';
import { SKIP_GLOBAL_LOADER } from '../../../../core/loader/loader.interceptor';
import { Paginate, Pagination } from '../../../models/pagination.model';
import { ResponseDto } from '../../../models/response-dto.model';
import { Nullable } from '../../../types/nullable.type';
import { removeDuplicatesByField } from '../../../utils/array.utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../utils/pagination.utils';
import { GroupedChat } from '../models/chat-grouped.model';
import { ChatMessage } from '../models/chat-message.model';
import { Chat } from '../models/chat.model';
import { groupChats } from '../utils/grouping.util';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  private _chats$ = new BehaviorSubject<Nullable<GroupedChat[]>>(null);
  private _chatsPagination$ = new BehaviorSubject<Nullable<Pagination>>(null);
  private _chat$ = new BehaviorSubject<Nullable<Chat>>(null);
  private _chatId$ = new BehaviorSubject<Nullable<string>>(null);

  chats$ = this._chats$.asObservable();
  chatsPagination$ = this._chatsPagination$.asObservable();
  chat$ = this._chat$.asObservable();
  chatId$ = this._chatId$.asObservable();

  getChats(page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, query?: string): Observable<GroupedChat[]> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (query !== undefined) {
      params = params.set('query', query);

      this.clearChats([]);
    }

    return this.http.get<ResponseDto<Paginate<Chat>>>(`/chat`, { params }).pipe(
      tap((response) => this._chatsPagination$.next(response.data.pagination)),
      map((response) => response.data.items),
      withLatestFrom(this.chats$),
      map(([chats, currentChats]) =>
        groupChats(chats, chats.length ? currentChats : []).map((groupedChat) => ({
          ...groupedChat,
          chats: removeDuplicatesByField(groupedChat.chats, 'chatId'),
        }))
      ),
      tap((chats) => this._chats$.next(chats))
    );
  }

  getChatById(id: string): Observable<Chat> {
    return this.http.get<ResponseDto<Chat>>(`/chat/${id}`).pipe(
      map((response) => response.data),
      tap((chat) => this.updateChat(chat)),
      catchError((error) => {
        this.router.navigate([`/chat`]);
        return of(error);
      })
    );
  }

  clearChats(defaultValue?: GroupedChat[]): void {
    this._chats$.next(defaultValue || null);
    this._chatsPagination$.next(null);
  }

  createNewChat(message: string, agent: string): Observable<Chat> {
    return this.http.post<ResponseDto<Chat>>(`/chat`, { message, vertical: agent }).pipe(
      map((response) => response.data),
      tap((chat) => {
        this.clearChats();
        this.getChats().subscribe();
        this.router.navigate(['chat', chat.chatId]);
      })
    );
  }

  sendMessage(chatId: string, message: string): Observable<ChatMessage> {
    return this.http
      .post<ResponseDto<Chat>>(
        `/chat/${chatId}`,
        { message },
        { context: new HttpContext().set(SKIP_GLOBAL_LOADER, true) }
      )
      .pipe(
        map((response) => response.data),
        map((data) => data.messages.at(-1) as ChatMessage),
        tap((chatMessage) => this.addUserMessageToChat(chatMessage))
      );
  }

  deleteChat(chatId: string): Observable<void> {
    return this.http.delete<void>(`/chat/${chatId}`).pipe(
      tap(() => {
        this.clearChats();
        this.getChats().subscribe();
      })
    );
  }

  createQAChat(message: string, bundleId: string, agent: string): Observable<Chat> {
    return this.http
      .post<ResponseDto<Chat>>(`/chat/qa`, { message, bundleId, vertical: agent })
      .pipe(map((response) => response.data));
  }

  addUserMessageToChat(message: ChatMessage): void {
    this.chat$.pipe(filter(Boolean), take(1)).subscribe((chat) => {
      chat.messages = chat.messages.filter(({ loading }) => !loading);
      chat.messages = [...chat.messages, message];

      if (!message.isChatBot) {
        const chatLoadingMessage = {
          loading: true,
          createdAt: new Date().toISOString(),
          message: '',
          isChatBot: true,
        };

        chat.messages = [...chat.messages, chatLoadingMessage];
      }

      this.updateChat(chat);
    });
  }

  setSelectedChatId(id: Nullable<string>) {
    this._chatId$.next(id);
  }

  private updateChat(chat: Chat) {
    this._chat$.next(JSON.parse(JSON.stringify(chat)));
  }
}
