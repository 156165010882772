export const removeDuplicatesByField = <T>(array: T[], field: keyof T) => {
  const seenFields = new Set();

  return array.filter((item) => {
    if (seenFields.has(item[field])) {
      return false;
    } else {
      seenFields.add(item[field]);
      return true;
    }
  });
};
