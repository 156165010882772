import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { LoaderService } from './loader.service';

export const SKIP_GLOBAL_LOADER = new HttpContextToken<boolean>(() => false);

export const loaderInterceptor: HttpInterceptorFn = (request, next) => {
  const loaderService = inject(LoaderService);

  !request.context.has(SKIP_GLOBAL_LOADER) && loaderService.setLoading(true, request.url);

  return next(request).pipe(finalize(() => loaderService.setLoading(false, request.url)));
};
