import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loading$ = new BehaviorSubject(false);

  loading$ = this._loading$.asObservable();

  loadingMap = new Map<string, boolean>();

  setLoading(loading: boolean, url: string): void {
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this._loading$.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }

    setTimeout(() => {
      this.loadingMap.size === 0 && this._loading$.next(false);
    }, 500);
  }
}
