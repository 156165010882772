import { GroupedChat } from '../models/chat-grouped.model';
import { Chat } from '../models/chat.model';

export const groupChats = (chats: Chat[], initialChats?: GroupedChat[] | null): GroupedChat[] => {
  const groupedChats: GroupedChat[] = initialChats || [];

  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate);
  const thirtyDaysAgo = new Date(currentDate);

  sevenDaysAgo.setDate(currentDate.getDate() - 7);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  (chats || []).forEach((chat, index) => {
    let category: string;
    let year: number | undefined;

    if (index < 5) {
      category = 'initial';
    } else {
      const lastUpdate = new Date(chat.lastUpdateAt);

      if (lastUpdate >= sevenDaysAgo) {
        category = 'common.filters.last-seven-days';
      } else if (lastUpdate >= thirtyDaysAgo) {
        category = 'common.filters.last-thirty-days';
      } else {
        year = lastUpdate.getFullYear();
        category = `common.months.${lastUpdate.getMonth()}`;
      }
    }

    const groupedChat = groupedChats.find(
      (groupedChat) => groupedChat.category === category && groupedChat.year === year
    );

    if (!groupedChat) {
      groupedChats.push({
        category,
        year,
        chats: [chat],
      });
    }

    groupedChat?.chats.push(chat);
  });

  return groupedChats;
};
