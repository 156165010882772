import { KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { TippyDirective } from '@ngneat/helipopper';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs';
import { InfiniteScrollDirective } from '../../../core/directives/infinite-scroll.directive';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { ChatService } from '../../data/data-access-chat/service/chat.service';
import { Nullable } from '../../types/nullable.type';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils';
import { DEFAULT_DEBOUNCE_TIME } from '../../utils/search.utils';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { SearchIconComponent } from '../icons/search-icon/search-icon.component';

export const SHOW_CHAT_HISTORY_DATA_KEY = '[ShowChatHistory]';

@Component({
  selector: 'app-chat-history',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    KeyValuePipe,
    RouterLink,
    SearchIconComponent,
    TranslocoPipe,
    InfiniteScrollDirective,
    ReactiveFormsModule,
    TippyDirective,
  ],
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
})
export class ChatHistoryComponent extends UiKitBaseComponent implements OnInit {
  private readonly chatService = inject(ChatService);
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly destroyRef = inject(DestroyRef);

  activeChatId = input<Nullable<string>>();

  groupedChats = toSignal(this.chatService.chats$);

  search = new FormControl('');
  query?: string;

  private page = DEFAULT_PAGE;

  ngOnInit() {
    this.getChats();

    this.search.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        filter((text) => !text?.length || text.length >= 2)
      )
      .subscribe((query) => {
        this.query = query as string;

        this.getChats();
      });
  }

  openDeleteChatDialog(id: string): void {
    this.confirmDialogService.open({
      message: 'chat.history.delete-chat.confirm-dialog.message',
    });

    this.confirmDialogService.action$.pipe(take(1)).subscribe((deleteChat) => {
      if (deleteChat) {
        this.chatService.deleteChat(id).subscribe();
      }
    });
  }

  loadNextPage() {
    this.chatService.chatsPagination$.pipe(filter(Boolean), take(1)).subscribe((pagination) => {
      const nextPage = pagination.nextPage;

      if (nextPage) {
        this.page = nextPage;

        this.getChats();
      }
    });
  }

  private getChats(): void {
    this.chatService.getChats(this.page, DEFAULT_PAGE_SIZE, this.query).subscribe();
  }
}
